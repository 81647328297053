import { dateAfter } from 'api/helpers/format/date';
import { isFeatureEnabled, features } from 'components/Feature';
import { getAccountDetails, getAccountCurrentMembershipPlan } from 'api/selectors';
import { useSelector } from 'react-redux';
import { isSitterOnly, isMember } from 'utils/account';
import { VariationTypes } from 'containers/ExperimentalFeature';

export const HIDE_FAVOURITES_TEST_DATE = new Date('2024-04-19');
const blocklist = ['8997954'];

const checkProfileId = ({ id }) => {
    if (!id) return false;
    if (id % 2 === 0) {
        return true;
    }
    return false;
};

const userIsEllegibleForFavouritesTest = (details, membership) => {
    const isNewProfile = dateAfter(details.joinDate, HIDE_FAVOURITES_TEST_DATE);
    const isSitter = isSitterOnly(membership);
    const isActiveMember = isMember(membership);
    const isBlocked = blocklist.includes(details.id);
    return isNewProfile && isSitter && isActiveMember && !isBlocked;
};
// Hook for checking if the user should see the hide favourites test
// The user should see the test if they are a sitter, have an active membership and are a new user (joined after the test date)
// also the user should have an even profile id
const useHideFavouritesCheck = () => {
    const details = useSelector(getAccountDetails);
    const membership = useSelector(getAccountCurrentMembershipPlan);
    if (!details || !membership) return false;
    const idIsEven = checkProfileId(details);
    const isFlagEnabled = isFeatureEnabled({ name: features.HIDE_FAVOURITES });
    const userEllegible = userIsEllegibleForFavouritesTest(details, membership);
    return idIsEven && isFlagEnabled && userEllegible;
};

// Hook for checking if the user should see the analytics for the test above
export const useSeeFavouritesAnalytics = () => {
    let shouldSeeFavouritesAnalytics = true;
    const details = useSelector(getAccountDetails);
    const membership = useSelector(getAccountCurrentMembershipPlan);
    if (!details || !membership) shouldSeeFavouritesAnalytics = false;
    const idIsEven = checkProfileId(details);
    const variation = idIsEven ? VariationTypes.VARIATION1 : VariationTypes.CONTROL;
    const isFlagEnabled = isFeatureEnabled({ name: features.HIDE_FAVOURITES });
    const userEllegible = userIsEllegibleForFavouritesTest(details, membership);
    if (!isFlagEnabled || !userEllegible) shouldSeeFavouritesAnalytics = false;
    return [shouldSeeFavouritesAnalytics, variation];
};

export default useHideFavouritesCheck;
